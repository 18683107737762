<template>
<div class="departments">
  <el-row type="flex" justify="center">
    <el-col :md="20" :lg="18" :xl="15">
      <div class="departments-wrap">


        <!--2024-9-17 XH 备忘：部分新业务没有对应图标，考虑画新的，或者全删掉-->
        <div class="item internal" @click="goDepartment('generalMedicine')">
          <!-- <div class="image"><img src="@/assets/departments/logo_internal.svg" alt="" height="20px" width="20px"></div> -->
          <div class="text-ja">総合内科</div>
          <div class="separator"></div>
          <div class="text-en"><span>G</span>eneral <span>M</span>edicine</div>
        </div>
        <div class="item fever" @click="goDepartment('fever')">
          <!-- <div class="image"><img src="@/assets/departments/logo_internal.svg" alt="" height="20px" width="20px"></div> -->
          <div class="text-ja">発熱外来</div>
          <div class="separator"></div>
          <div class="text-en"><span>F</span>ever <span>O</span>utpatient</div>
        </div>
        <div class="item corona" @click="goDepartment('corona')">
          <!-- <div class="image"><img src="@/assets/departments/logo_kampo.svg" alt="" height="20px" width="20px"></div> -->
          <div class="text-ja">コロナ予防接種</div>
          <div class="separator"></div>
          <div class="text-en"><span>C</span>orona <span>V</span>accination</div>
        </div>
        <div class="item diet" @click="goDepartment('diet')">
          <!-- <div class="image"><img src="@/assets/departments/logo_tumor.svg" alt="" height="20px" width="20px"></div> -->
          <div class="text-ja">メディカルダイエット</div>
          <div class="separator"></div>
          <div class="text-en"><span>R</span>educe <span>W</span>eight</div>
        </div>
        
        <div class="item placenta" @click="goDepartment('placenta')">
          <!-- <div class="image"><img src="@/assets/departments/logo_second.svg" alt="" height="20px" width="20px"></div> -->
          <div class="text-ja">プラセンタ</div>
          <div class="separator"></div>
          <div class="text-en"><Span>P</Span>lacenta </div>
        </div>
        <div class="item Stemsup" @click="goDepartment('stemsup')">
          <!-- <div class="image"><img src="@/assets/departments/logo_placenta.svg" alt="" height="20px" width="20px"></div> -->
          <div class="text-ja">ステムサップ</div>
          <div class="separator"></div>
          <div class="text-en"><span>S</span>tem<span>S</span>up</div>
        </div>
      </div>
    </el-col>
  </el-row>
</div>
</template>

<script>
export default {
  name: "HomeDepartments",
  methods: {
    goDepartment(name) {
      this.$router.push(`/department/${name}`)
    },
    toUrl(){
      window.open('https://www.hope-tsukiji.com','_self')
    }
  }
}
</script>

<style scoped>
.departments {
  margin: 0 auto;
  padding: 40px 0;
}
.departments-wrap {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.item {
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px #CCCCCC;
  border-radius: 5px;
  cursor: pointer; /*鼠标放上去，变成手的形状*/
  text-align: center;
  margin-left: 30px;
  padding: 20px 10px;
  width: 160px;
}
.item .text-ja {
  font-size: 16px;
  color: #FFFFFF;
  margin-top: 14px;
  font-weight: bold;
}
.item .text-en {
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 14px;
}

/*鼠标放上面前后变化*/
/* 胃腸科 */
.internal {
  /* background-color: #e88383;
  color: #FFFFFF;
  border: 1px solid #e88383; */
  background-color: #f67575;
  color: #FFFFFF;
  border: 1px solid #f67575;
  /* margin-left: 0; */
}
.internal .separator {
  border-top: 1px solid #FFFFFF;
  margin-top: 14px;
}
.internal .text-en>span {
  color: #FFFFFF;
}
.internal:hover, .internal:hover .text-ja, .internal:hover .text-en, .internal:hover .text-en>span {
  background-color: #FFFFFF;
  color: #f67575;
}
.internal:hover .separator {
  border-top: 1px solid #f67575;
}
/* 漢方内科 */
.fever {
  border: 1px solid #edb947;
  background-color: #edb947;
  color: #FFFFFF;
}
.fever .separator {
  border-top: 1px solid #FFFFFF;
  margin-top: 14px;
}
.fever .text-en>span {
  color: #FFFFFF;
}
.fever:hover, .fever:hover .text-ja, .fever:hover .text-en, .fever:hover .text-en>span {
  background-color: #FFFFFF;
  color: #e5d05e;
}
.fever:hover .separator {
  border-top: 1px solid #e5d05e;
}
/* 腫瘍外科 */
.diet {
  border: 1px solid #91c360;
  background-color: #91c360;
  color: #FFFFFF;
}
.diet .separator {
  border-top: 1px solid #FFFFFF;
  margin-top: 14px;
}
.diet .text-en>span {
  color: #FFFFFF;
}
.diet:hover, .diet:hover .text-ja, .diet:hover .text-en, .diet:hover .text-en>span {
  background-color: #FFFFFF;
  color: #91c360;
}
.diet:hover .separator {
  border-top: 1px solid #91c360;
}
/* セカンドオピニオン */
.placenta {
  border: 1px solid #70c9b5;
  background-color: #70c9b5;
  color: #FFFFFF;
}
.placenta .separator {
  border-top: 1px solid #FFFFFF;
  margin-top: 14px;
}
.placenta .text-en>span {
  color: #FFFFFF;
}
.placenta:hover, .placenta:hover .text-ja, .placenta:hover .text-en, .placenta:hover .text-en>span {
  background-color: #FFFFFF;
  color: #70c9b5;
}
.placenta:hover .separator {
  border-top: 1px solid #70c9b5;
}
/*CORONA*/
.corona {
  border: 1px solid #6598f0;
  background-color: #6598f0;
  color: #FFFFFF;
}
.corona .separator {
  border-top: 1px solid #FFFFFF;
  margin-top: 14px;
}
.corona .text-en>span {
  color: #FFFFFF;
}
.corona:hover, .corona:hover .text-ja, .corona:hover .text-en, .corona:hover .text-en>span {
  background-color: #FFFFFF;
  color: #6598f0;
}
.corona:hover .separator {
  border-top: 1px solid #62b6e2;
}

/* ダイエット */
.Stemsup {
  border: 1px solid #7887d9;
  background-color: #7887d9;
  color: #FFFFFF;
}
.Stemsup .separator {
  border-top: 1px solid #FFFFFF;
  margin-top: 14px;
}
.Stemsup .text-en>span {
  color: #FFFFFF;
}
.Stemsup:hover, .Stemsup:hover .text-ja, .Stemsup:hover .text-en, .Stemsup:hover .text-en>span {
  background-color: #FFFFFF;
  color: #7887d9;
}
.Stemsup:hover .separator {
  border-top: 1px solid #7887d9;
}
</style>
